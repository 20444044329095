import React from 'react'
import styled from 'styled-components'
import HeroSlider from 'components/new/hero-slider'
import Button from 'components/button/button'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import { graphql } from 'gatsby'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import BrandsModule from 'components/modules/brands'
import ProductCategoryGrid from 'components/new/product-category-grid'
import Spacer from 'components/new/spacer'
import SectionHeader from 'components/new/section-header'
import PromotionsSlider from 'components/new/promotions-slider'
import LocationsMapSearch from '~/components/locations-map/locations-map-search'
import UsedEquipmentQuickSearch from 'components/new/used-equipment-quick-search'
import HeaderWithLink from 'components/new/header-with-link'
import { Carousel, ProductCard, UsedEquipmentCard } from 'components/new/carousel'
import convertArrayToInlineList from 'utils/convert-array-to-inline-list'
import todayIsBetweenDates from 'utils/dates/today-is-between-dates'

const schemaMarkup = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  'url': 'https://www.hutsoninc.com/',
  'potentialAction': {
    '@type': 'SearchAction',
    'target': {
      '@type': 'EntryPoint',
      'urlTemplate': 'https://www.hutsoninc.com/search?search={search_term_string}',
    },
    'query-input': 'required name=search_term_string',
  },
})

const Index = props => {
  const {
    data: {
      allHutsonPromotion,
      allMachineFinderEquipment,
      allSanityLocation: { nodes: allSanityLocationNodes, states: allSanityLocationStates },
      locationStats: { totalCount: locationCount },
    },
    data,
  } = props

  // Hero slides
  const heroSlidesData = [
    {
      image: data.slideAriens.childImageSharp.gatsbyImageData,
      title: 'We have snow removal equipment',
      description:
        "Don't let this winter weather beat you. Get your snow removal equipment at Hutson.",
      link: '/locations/',
      buttonText: 'View Locations',
      overlayOpacity: 0.4,
      startDate: null,
      endDate: new Date('2025-01-13T00:00:00-06:00'),
    },
    {
      image: data.slideInspections.childImageSharp.gatsbyImageData,
      title: 'Announcing our new service plans',
      description: "Lock in today's labor rate and save on future maintenance costs",
      link: '/service/#service-programs',
      buttonText: 'Browse service plans',
      overlayOpacity: 0.4,
      startDate: null,
      endDate: new Date('2025-07-01T00:00:00-06:00'),
    },
    {
      image: data.slidePartsPromotions.childImageSharp.gatsbyImageData,
      title: 'Shop savings on parts & attachments',
      description:
        'Check out our current promotions on John Deere parts, attachments, merchandise & more!',
      outboundLink:
        'https://shop.deere.com/us/Promotions?dealer-id=034321&CID=LNK_DLR_enUS_OS_HutsonWebsite',
      buttonText: 'View current promotions',
      overlayOpacity: 0.5,
    },
    {
      image: data.slideCCE.childImageSharp.gatsbyImageData,
      title: '3.90% for 60 months',
      description: 'on used compact track loaders, skid steers & mini excavators',
      link: '/lp/eaeb1bda-f27e-4dc2-8e11-4268f524d3e8/',
      buttonText: 'Browse eligible units',
      overlayOpacity: 0.4,
      startDate: null,
      endDate: new Date('2024-10-26T00:00:00-06:00'),
    },
    {
      image: data.slidePrecisionAgEssentials.childImageSharp.gatsbyImageData,
      title: 'Precision ag essentials to connect your whole farm',
      description:
        'Start pulling valuable agronomic data out of your operation with Precision Ag Essentials from John Deere.',
      outboundLink:
        'https://www.deere.com/en/technology-products/precision-ag-technology/essentials/',
      buttonText: 'Learn more',
      overlayOpacity: 0.5,
    },
    {
      image: data.slideCUTsPriorYearModelSale.childImageSharp.gatsbyImageData,
      title: 'End of Season Sales Event',
      description:
        'Special clearance pricing on select John Deere compact utility tractors & Gators.',
      link: '/lp/end-of-season-sales-event/',
      buttonText: 'Browse our inventory',
      overlayOpacity: 0.4,
      startDate: null,
      endDate: new Date('2024-10-24T00:00:00-06:00'),
    },
    {
      image: data.slideInspections.childImageSharp.gatsbyImageData,
      title: 'Ultimate uptime service programs',
      description:
        'Keep your equipment running this summer with an ultimate uptime inspection from Hutson!',
      link: '/lp/inspections/',
      buttonText: 'Learn more',
      overlayOpacity: 0.4,
      startDate: null,
      endDate: new Date('2024-08-31T00:00:00-06:00'),
    },
    {
      image: data.slideCareers.childImageSharp.gatsbyImageData,
      title: 'Join our team!',
      description:
        'Interested in working for Hutson? View our current career opportunities and apply online today.',
      link: '/careers/',
      buttonText: 'Careers at Hutson',
      overlayOpacity: 0.1,
    },
  ]

  const heroSlides = heroSlidesData.filter(slide =>
    todayIsBetweenDates({
      start: slide.startDate,
      end: slide.endDate,
    })
  )

  // John Deere equipment
  // const [equipmentExpanded, setEquipmentExpanded] = useState(false)

  const equipment = [
    {
      link: '/agriculture/',
      image: data.harvestingImage,
      title: 'Ag Equipment',
      imageAlt: 'John Deere Combine',
    },
    {
      link: '/construction/',
      image: data.skidSteersImage,
      title: 'Compact Construction Equipment',
      imageAlt: 'John Deere Skid Steer',
    },
    {
      link: '/lawn-garden/gators/',
      image: data.gatorsImage,
      title: 'Gator UTVs',
      imageAlt: 'John Deere Crossover Gator',
    },
    {
      link: '/manitou/',
      image: data.materialHandlingEquipmentImage,
      title: 'Material Handling Equipment',
      imageAlt: 'John Deere Crossover Gator',
    },
    {
      link: '/lawn-garden/mowers/',
      image: data.resZeroTurnsImage,
      title: 'Mowers',
      imageAlt: 'John Deere Residential Zero-Turn',
    },
    {
      link: '/lawn-garden/compact-tractors/',
      image: data.compactTractorsImage,
      title: 'Compact Tractors',
      imageAlt: 'John Deere Compact Utility Tractor',
    },
  ] /*.map((item, index) => {
    if (!equipmentExpanded && index > 5) {
      item.containerProps = {
        'aria-hidden': true,
        'hidden': true,
      }
    }
    return item
  })*/

  // STIHL equipment
  const stihlEquipment = [
    {
      title: 'Augers and Drills',
      imageAlt: 'STIHL Augers and Drills',
      link: '/stihl/augers-and-drills/',
      image: data.augersDrillsImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Batteries and Accessories',
      imageAlt: 'STIHL Batteries and Accessories',
      link: '/stihl/batteries-and-accessories/',
      image: data.batteriesImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Bed Redefiners',
      imageAlt: 'STIHL Bed Redefiners',
      link: '/stihl/bed-redefiner/',
      image: data.bedRedefinersImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Blowers & Shredder Vacs',
      imageAlt: 'STIHL Blowers & Shredder Vacs',
      link: '/stihl/blowers-and-shredder-vacs/',
      image: data.blowersImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Chain Saws',
      imageAlt: 'STIHL Chain Saws',
      link: '/stihl/chain-saws/',
      image: data.chainsawsImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Concrete Cutters',
      imageAlt: 'STIHL Concrete Cutters',
      link: '/stihl/concrete-cutters/',
      image: data.concreteCuttersImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Cut-Off Machines',
      imageAlt: 'STIHL Cut-Off Machines',
      link: '/stihl/cut-off-machines/',
      image: data.cutOffMachinesImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Edgers',
      imageAlt: 'STIHL Edgers',
      link: '/stihl/edgers/',
      image: data.edgersImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Hand Tools',
      imageAlt: 'STIHL Hand Tools',
      link: '/stihl/hand-tools/',
      image: data.handToolsImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Hedge Trimmers',
      imageAlt: 'STIHL Hedge Trimmers',
      link: '/stihl/hedge-trimmers/',
      image: data.hedgeTrimmersImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Lawn Mowers',
      imageAlt: 'STIHL Lawn Mowers',
      link: '/stihl/lawn-mowers/',
      image: data.lawnMowersImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Multi-Task Tools',
      imageAlt: 'STIHL Multi-Task Tools',
      link: '/stihl/multi-task-tools/',
      image: data.kombisystemImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Pole Pruners',
      imageAlt: 'STIHL Pole Pruners',
      link: '/stihl/pole-pruners/',
      image: data.polePrunersImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Pressure Washers',
      imageAlt: 'STIHL Pressure Washers',
      link: '/stihl/pressure-washers/',
      image: data.pressureWashersImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Sprayers',
      imageAlt: 'STIHL Sprayers',
      link: '/stihl/sprayers',
      image: data.backpackSprayersImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Trimmers & Brushcutters',
      imageAlt: 'STIHL Trimmers & Brushcutters',
      link: '/stihl/trimmers-and-brushcutters/',
      image: data.trimmersImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Wet/Dry Vacuums',
      imageAlt: 'STIHL Wet/Dry Vacuums',
      link: '/stihl/wet-dry-vacuums/',
      image: data.vacuumsImage.childImageSharp.gatsbyImageData,
    },
  ]

  // Promotions
  const promotions = allHutsonPromotion
    ? allHutsonPromotion.nodes.map(node => {
        const image = node.image
        return {
          ...node,
          image: image.childImageSharp.gatsbyImageData,
        }
      })
    : []

  const featuredUsedEquipment = allMachineFinderEquipment.nodes

  allSanityLocationStates.sort((a, b) => b.totalCount - a.totalCount)

  const statesList = allSanityLocationStates.map(state => state.fieldValue)

  const statesListString = convertArrayToInlineList(statesList, {
    conjunction: '&',
    oxfordComma: false,
  })

  return (
    <Layout>
      <Helmet>
        <title>Hutson Inc - John Deere Dealer in {statesListString}</title>
        <meta
          name='description'
          content={`Hutson Inc is a leader in new and used John Deere equipment and provides high-quality parts and service across its ${locationCount} stores located in ${statesListString}.`}
        />
        <script type='application/ld+json'>{schemaMarkup}</script>
      </Helmet>

      <HeroSlider items={heroSlides} />

      <Content kind='full'>
        <SectionHeader>Explore our equipment</SectionHeader>
        <SectionHeaderP>Hutson offers a complete line-up of John Deere equipment</SectionHeaderP>
        <Spacer size='xl' variable />
        <ProductCategoryGrid categories={equipment} id='john-deere-equipment-grid' />
        {/* <ExpandButtonContainer>
          <Button
            onClick={() => setEquipmentExpanded(!equipmentExpanded)}
            color='green'
            ghost
            aria-expanded={equipmentExpanded}
            aria-controls='john-deere-equipment-grid'
          >
            {equipmentExpanded ? <>Show less equipment</> : <>Show more equipment</>}
          </Button>
        </ExpandButtonContainer> */}
      </Content>

      <LightBackground>
        <Content kind='full'>
          <UsedEquipmentGrid>
            <UsedEquipmentSearchColumn>
              <SectionHeader>Search Used Equipment</SectionHeader>
              <SectionHeaderP>Search our large pre-owned inventory</SectionHeaderP>
              <Spacer size='m' variable />
              <UsedEquipmentQuickSearch />
            </UsedEquipmentSearchColumn>
            <FeaturedUsedEquipmentColumn>
              <FeaturedUsedEquipmentHeader to='/used-equipment/' linkText='View all used equipment'>
                Featured Used Equipment
              </FeaturedUsedEquipmentHeader>
              <Spacer size='m' />
              <UsedEquipmentCarousel
                breakPoints={[
                  { width: 1, itemsToShow: 1 },
                  { width: 600, itemsToShow: 2, itemsToScroll: 2 },
                  { width: 900, itemsToShow: 3, itemsToScroll: 3 },
                ]}
                enablePausePlay={true}
              >
                {featuredUsedEquipment.map(({ id, ...item }) => (
                  <UsedEquipmentCard {...item} key={id} />
                ))}
              </UsedEquipmentCarousel>
            </FeaturedUsedEquipmentColumn>
          </UsedEquipmentGrid>
        </Content>
      </LightBackground>
      <Content kind='full'>
        <SectionHeader>Shop STIHL Outdoor Power Equipment</SectionHeader>
        <SectionHeaderP>Whatever the project, we've got a STIHL for you</SectionHeaderP>
        <Spacer size='xl' variable />
        <Carousel>
          {stihlEquipment.map(item => (
            <ProductCard {...item} key={item.link} />
          ))}
        </Carousel>
      </Content>
      <BrandsModule />
      {promotions.length > 0 ? (
        <LightBackground>
          <Content kind='full'>
            <SectionHeader>Current promotions at Hutson</SectionHeader>
            <SectionHeaderP>Check out our latest promotions, offers, and discounts</SectionHeaderP>
            <Spacer size='xl' variable />
            <PromotionsSlider kind='default' items={promotions} />
          </Content>
        </LightBackground>
      ) : null}
      <Content kind='full'>
        <SectionHeader>Support and services</SectionHeader>
        <SectionHeaderP>Parts, service, precision ag services, and more</SectionHeaderP>
        <Spacer size='xl' variable />
        <SupportGrid>
          <SupportCard>
            <SupportTag>Parts</SupportTag>
            <H3>Right parts at the right price</H3>
            <P>See our parts offerings and order parts online.</P>
            <Spacer size='s' />
            <Button as={ButtonLink} to='/parts/' color='green' ghost>
              Learn more
            </Button>
          </SupportCard>
          <SupportCard>
            <SupportTag>Service</SupportTag>
            <H3>Stay up and running</H3>
            <P>Schedule a service online.</P>
            <Spacer size='s' />
            <Button as={ButtonLink} to='/service/' color='green' ghost>
              Learn more
            </Button>
          </SupportCard>
          <SupportCard>
            <SupportTag>Precision Ag</SupportTag>
            <H3>Maximize your yields</H3>
            <P>Learn more about our precision ag services.</P>
            <Spacer size='s' />
            <Button as={ButtonLink} to='/precision-ag/' color='green' ghost>
              Learn more
            </Button>
          </SupportCard>
          <SupportCard>
            <SupportTag>Connected Support</SupportTag>
            <H3>Get connected, stay running</H3>
            <P>See how Connected Support can help reduce downtime.</P>
            <Spacer size='s' />
            <Button as={ButtonLink} to='/connected-support/' color='green' ghost>
              Learn more
            </Button>
          </SupportCard>
        </SupportGrid>
      </Content>
      <LightBackground>
        <Content yPadding={false}>
          <Spacer size='xxl' type='padding' variable />
          <SectionHeader>Our Locations</SectionHeader>
          <SectionHeaderP>
            {locationCount} stores across {statesListString}
          </SectionHeaderP>
          <Spacer size='l' type='padding' variable />
        </Content>
        <LocationsMapSearch locations={allSanityLocationNodes} />
      </LightBackground>
    </Layout>
  )
}

const UsedEquipmentGrid = styled.div`
  @media (min-width: 1200px) {
    ${clearfix};
    display: flex;
  }
`

const UsedEquipmentSearchColumn = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 36px;

  @media (min-width: 1200px) {
    ${column('2/5', '48px')}
    padding: 36px 24px;
  }
`

const FeaturedUsedEquipmentColumn = styled.div`
  padding: 8px 0 0;

  @media (max-width: 1199px) {
    margin-top: 48px;
  }

  @media (min-width: 1200px) {
    ${column('3/5', '48px')}
  }
`

const FeaturedUsedEquipmentHeader = styled(HeaderWithLink)`
  padding: 0 8px;
`

const UsedEquipmentCarousel = styled(Carousel)`
  .nav-arrow {
    padding: 12px !important;
  }
`

const SupportGrid = styled.div`
  ${clearfix};
  display: flex;
  flex-wrap: wrap;
`

const SupportCard = styled.div`
  background-color: ${props => props.theme.color.n20};
  margin-bottom: 24px;
  padding: 24px;
  width: 100%;

  @media (min-width: 600px) {
    ${column('1/2', '24px')};
    display: flex;
    flex-direction: column;

    .spacer {
      flex-grow: 2;
    }
  }

  @media (min-width: 1000px) {
    ${column('1/4', '24px')};
    margin-bottom: 0;
  }
`

const SupportTag = styled.p`
  color: ${props => props.theme.color.g400};
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 16px;
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  max-width: 200px;
`

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const SectionHeaderP = styled(P)`
  margin-top: 0;
  text-align: center;
`

// const ExpandButtonContainer = styled.div`
//   button {
//     margin: 0 auto;
//   }
// `

export const pageQuery = graphql`
  query indexPageQuery {
    # Locations
    allSanityLocation(sort: { fields: title, order: ASC }) {
      nodes {
        address
        city
        directionsLink
        geolocation {
          lat
          lng
        }
        hours {
          sunday
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
        }
        locationNumber
        phoneNumber
        slug
        specialHours {
          date
          hours
          title
        }
        state
        status
        title
        zip
      }
      states: group(field: state) {
        totalCount
        fieldValue
      }
    }
    locationStats: allSanityLocation(
      filter: { status: { ne: "permanently-closed" }, retailLocation: { eq: true } }
    ) {
      totalCount
    }
    # Promotions
    allHutsonPromotion(sort: { fields: [endDate], order: DESC }, limit: 5) {
      nodes {
        ...HutsonPromo
      }
    }
    # Used Equipment
    allMachineFinderEquipment(
      sort: { fields: [stockNumber], order: ASC }
      filter: {
        reducedPrice: { eq: true }
        subcategory: {
          title: {
            in: [
              "Row Crop Tractors"
              "Utility Tractors"
              "Compact Utility Tractors"
              "Articulated 4WD Tractors"
              "Track Tractors"
              "Combines"
              "Drawn Planters"
              "Air Drills and Seeders"
              "Self-Propelled Applicators"
            ]
          }
        }
      }
      limit: 8
    ) {
      nodes {
        id
        initialPrice
        price
        primaryImage {
          large
          regular
        }
        slug
        title
        stockNumber
      }
    }
    # Slides
    slideBlackFriday: file(relativePath: { eq: "homepage/black-friday-hero.jpg" }) {
      ...FullWidthImage
    }
    slideAriens: file(relativePath: { eq: "homepage/ariens-snow-blower-slide.jpg" }) {
      ...FullWidthImage
    }
    slideCompactTractors: file(relativePath: { eq: "homepage/get-in-the-seat-slide.jpg" }) {
      ...FullWidthImage
    }
    slideCCE: file(relativePath: { eq: "homepage/cce-slide.jpg" }) {
      ...FullWidthImage
    }
    slidePartsPromotions: file(relativePath: { eq: "homepage/parts-promotions-slide.jpg" }) {
      ...FullWidthImage
    }
    slidePrecisionAgEssentials: file(
      relativePath: { eq: "homepage/precision-ag-essentials-slide.jpg" }
    ) {
      ...FullWidthImage
    }
    slideCUTsPriorYearModelSale: file(
      relativePath: { eq: "homepage/cuts-prior-year-model-sale-slide.jpg" }
    ) {
      ...FullWidthImage
    }
    slideInspections: file(relativePath: { eq: "homepage/inspections-slide.jpg" }) {
      ...FullWidthImage
    }
    slideCareers: file(relativePath: { eq: "homepage/careers-slide.jpg" }) {
      ...FullWidthImage
    }
    # John Deere images
    resZeroTurnsImage: file(relativePath: { eq: "lawn-and-garden/z300-series.jpg" }) {
      ...FloatingGridImage
    }
    compactTractorsImage: file(relativePath: { eq: "lawn-and-garden/1-family.jpg" }) {
      ...FloatingGridImage
    }
    gatorsImage: file(relativePath: { eq: "lawn-and-garden/full-size-crossover-gators.jpg" }) {
      ...FloatingGridImage
    }
    materialHandlingEquipmentImage: file(relativePath: { eq: "manitou/manitou-telehandlers.jpg" }) {
      ...FloatingGridImage
    }
    harvestingImage: file(relativePath: { eq: "agriculture/s-series.jpg" }) {
      ...FloatingGridImage
    }
    skidSteersImage: file(relativePath: { eq: "construction/skid-steers.jpg" }) {
      ...FloatingGridImage
    }
    # STIHL images
    augersDrillsImage: file(relativePath: { eq: "stihl/augers-and-drills/wood-boring-drill.jpg" }) {
      ...StihlCategoryBoxImage
    }
    batteriesImage: file(relativePath: { eq: "stihl/batteries-and-accessories/chargers.jpg" }) {
      ...StihlCategoryBoxImage
    }
    bedRedefinersImage: file(
      relativePath: { eq: "stihl/bed-redefiner/professional-bed-redefiner.jpg" }
    ) {
      ...StihlCategoryBoxImage
    }
    blowersImage: file(
      relativePath: { eq: "stihl/blowers-and-shredder-vacs/homeowner-blowers.jpg" }
    ) {
      ...StihlCategoryBoxImage
    }
    chainsawsImage: file(relativePath: { eq: "stihl/chain-saws.jpg" }) {
      ...StihlCategoryBoxImage
    }
    concreteCuttersImage: file(
      relativePath: { eq: "stihl/concrete-cutters/professional-concrete-cutters.jpg" }
    ) {
      ...StihlCategoryBoxImage
    }
    cutOffMachinesImage: file(
      relativePath: { eq: "stihl/cut-off-machines/professional-cut-off-machines.jpg" }
    ) {
      ...StihlCategoryBoxImage
    }
    edgersImage: file(relativePath: { eq: "stihl/edgers/homeowner-edger.jpg" }) {
      ...StihlCategoryBoxImage
    }
    handToolsImage: file(relativePath: { eq: "stihl/hand-tools/battery-hand-tools.jpg" }) {
      ...StihlCategoryBoxImage
    }
    hedgeTrimmersImage: file(relativePath: { eq: "stihl/hedge-trimmers.jpg" }) {
      ...StihlCategoryBoxImage
    }
    lawnMowersImage: file(relativePath: { eq: "stihl/lawn-mowers/homeowner-lawn-mowers.jpg" }) {
      ...StihlCategoryBoxImage
    }
    kombisystemImage: file(
      relativePath: { eq: "stihl/multi-task-tools/homeowner-kombisystem.jpg" }
    ) {
      ...StihlCategoryBoxImage
    }
    polePrunersImage: file(relativePath: { eq: "stihl/pole-pruners.jpg" }) {
      ...StihlCategoryBoxImage
    }
    pressureWashersImage: file(relativePath: { eq: "stihl/pressure-washers.jpg" }) {
      ...StihlCategoryBoxImage
    }
    backpackSprayersImage: file(relativePath: { eq: "stihl/sprayers/backpack-sprayers.jpg" }) {
      ...StihlCategoryBoxImage
    }
    trimmersImage: file(relativePath: { eq: "stihl/trimmers-and-brushcutters.jpg" }) {
      ...StihlCategoryBoxImage
    }
    vacuumsImage: file(relativePath: { eq: "stihl/wet-dry-vacuums/homeowner-vacuum.jpg" }) {
      ...StihlCategoryBoxImage
    }
  }
`

export default Index
